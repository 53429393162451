#reports .btn-outline-primary {
    outline: 1px solid #4263eb;
    background: transparent;
    color: #4263eb;
    border-radius: 0.5rem;
}

#reports .btn-primary {
    background: #4263eb;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
}

#reports label {
    font-size: 0.8rem;
}

#reports input, #reports select {
    box-sizing: border-box;
    font-size: 0.8rem !important;
    padding: 0.5rem 0.5rem !important;
}

#reports table thead {
    font-size: 13px;
    color: #767196;
    background: #fafafa;
}

#reports table tbody {
    font-size: 13px;
    color: #151940;
}

#reports .react-select__input {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#paginate li:first-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #151940;
}

#paginate .page-item.active .page-link {
    background: #4263EB;
    border: #4263EB;
}