#buyer h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #151940;
}

#buyer a.btn {
    /* box-shadow: none; */
    background: #4263EB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

#buyer table thead,  table.commonTable thead {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #767196;
    background: #FAFAFA;
}

#buyer table tbody ,  table.commonTable tbody {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #151940;
}

.modal .modal-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 33px;
    color: #151940;
    flex-grow: 1;
    text-align: center;
}

.modal .modal-header, .modal .modal-footer {
    border: none;
}

.modal button[type=submit] {
    /* box-shadow: none; */
    background: #4263EB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0.6rem 3rem;
    border:none;

}

.modal button[type=reset] {
    /* box-shadow: none; */
    background: #F8F8F8;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #767196;
    padding: 0.6rem 1.75rem;
}

.modal label {
    font-size: 14px;
}

.modal input {
    padding: 0.5rem  0.75rem;
    /* padding: 0.5rem 0.75rem !important; */
    font-size: 15px;
    letter-spacing: 1px;
}


#buyerAdd button[type=submit] {
    /* box-shadow: none; */
    background: #4263EB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0.6rem 3rem;
}

#buyerAdd button[type=reset] {
    /* box-shadow: none; */
    background: #F8F8F8;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #767196;
    padding: 0.6rem 1.75rem;
}

#buyerAdd label {
    font-size: 14px;
    color: #151940;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

}

#buyerAdd input {
    padding: 0.5rem 0.75rem;
    font-size: 15px;
    letter-spacing: 1px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
}   

#buyerAdd select {
    box-sizing: border-box;
    padding: 0.5rem 0.75rem;
    font-size: 15px;
    letter-spacing: 1px;
}

#buyerAdd .react-select__input {
    padding-left: 0px ;
    padding-right: 0px ;
  }

 .branchModalCancel{
    
    box-sizing: border-box;
    /* position: absolute; */
    width: 136px;
    height: 45px;
    left: 941px;
    top: 754px;
    background: #F8F8F8;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    margin-right: 1.5rem;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    /* Grey Text */

    color: #767196;
}

#buyerSave button[type=submit] {
    
width: 136px;
height: 50px;
left: 1101px;
top: 754px;
background: #4263EB;
border-radius: 8px;

}
#modalhead{
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #151940;
    text-align: center;
    font-family: poppins;
  }
  #createNew{
    background: #4263EB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    padding: 0.6rem 3rem;
  }
  #downloadbtn{
    background: #4263EB;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      border:none;
      padding: 0.4rem 1rem;
      height:"45px"
  }
  #clearbtn{
    background: #4263EB;
      border-radius: 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      border:none;
      /* margin-left: -13px; */
      margin-left: 63px;
      padding: 0.6rem 2rem;
  }
  