#setting h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #151940;
}

#setting a.btn {
    /* box-shadow: none; */
    background: #4263eb;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
}

#setting table thead {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #767196;
    background: #fafafa;
}

#setting table tbody {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #151940;
}

.modal .modal-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 33px;
    color: #151940;
    flex-grow: 1;
    text-align: center;
}

.modal .modal-header,
.modal .modal-footer {
    border: none;
}

.modal button[type="submit"] {
    /* box-shadow: none; */
    background: #4263eb;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    padding: 0.6rem 3rem;
}

.modal button[type="reset"] {
    /* box-shadow: none; */
    background: #f8f8f8;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #767196;
    padding: 0.6rem 1.75rem;
}

.modal label {
    font-size: 14px;
}

.modal input {
    padding: 0.5rem 0.75rem;
    font-size: 15px;
    letter-spacing: 1px;
}

.edit {
    background-color: white;
}


#setting button[type=submit] {
    /* box-shadow: none; */
    background: #4263EB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    padding: 0.6rem 3rem;
}

#setting button[type=reset] {
    /* box-shadow: none; */
    background: #F8F8F8;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #767196;
    padding: 0.6rem 1.75rem;
}

#setting label {
    font-size: 14px;
    color: #151940;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}

#setting input {
    padding: 0.5rem 0.75rem;
    font-size: 15px;
    letter-spacing: 1px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
}

#setting input.form-check-input {
    padding: 0.5rem 0.5rem;
    font-size: 15px;
    letter-spacing: 1px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
}

#setting select {
    box-sizing: border-box;
    padding: 0.5rem 0.75rem;
    font-size: 15px;
    letter-spacing: 1px;
}

#setting .react-select__input {
    padding-left: 0px;
    padding-right: 0pxt;
}

#prodcatAdd .react-select__input {
    padding-left: 0px;
    padding-right: 0px;
}

.settingModalCancel {

    box-sizing: border-box;
    /* position: absolute; */
    width: 136px;
    height: 45px;
    left: 941px;
    top: 754px;
    background: #F8F8F8;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    margin-right: 1.5rem;
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    /* Grey Text */

    color: #767196;
}

#settingSave button[type=submit] {
    width: 136px;
    height: 50px;
    left: 1101px;
    top: 754px;
    background: #4263EB;
    border-radius: 8px;

}