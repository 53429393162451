#leftImg {
    height: 100vh;
    padding: 1rem 0rem 1rem 1rem;
}

@media screen and (max-width: 576px) {
    #leftImg {
        height: calc(100vh - 1rem);
        padding: 1rem 1rem 1rem 1rem;
    }
}

.card {
    border: none;
    background: #FFFFFF;
    box-shadow: -16px 8px 51px rgba(0, 0, 0, 0.07);
    border-radius: 30px;
}


p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #151940;
}

h1 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #151940;
}

label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #949CA9;
}

.text-grey {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #767196;
}

input {
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    letter-spacing: 0.2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #767196;
}

input[type='text'] {
    padding: 0.8rem 0.8rem;
}

select {
    box-sizing: content-box;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 0.8rem 0rem 0.8rem 0.8rem;
    letter-spacing: 0.2rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #767196;
}

.otp {
    margin: 0rem 0.8rem;
    padding: 0.8rem;
    border: none;
    max-width: 65px;
    background: #EEF1FF;
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 130%;
    color: #151940;
    text-align: center;
}

.otp:first-child {
    margin: 0rem 0.8rem 0rem 0rem;
}

.otp:focus {
    border: 1px solid #E8E8E8;
}

.no-border-right {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.no-border-left {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.BreadcrumbPaths{
    display: flex;
    margin-left: 9px;
    width: 17px;
    height: 23px;
    margin-top: 23px;
}
.userimage{
    position: absolute;
width: 720px;
height: 960px;
left: 0px;
top: 0px;
display: inline;
/* height: 0; */

}
.imgsfluid{
    margin-left: 19px;
    width: 17px;
    height: 23px;
    margin-top: 13px;
}