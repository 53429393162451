#user h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #151940;
}

#user a.btn {
  /* box-shadow: none; */
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

#user table thead {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: #767196;
  background: #fafafa;
}

#user table tbody {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #151940;
}

.modal .modal-title {
  font-weight: 600;
  font-size: 19px;
  line-height: 33px;
  color: #151940;
  flex-grow: 1;
  text-align: center;
}

.modal .modal-header,
.modal .modal-footer {
  border: none;
}

.modal button[type="submit"] {
  /* box-shadow: none; */
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 0.6rem 3rem;
}

.modal button[type="reset"] {
  /* box-shadow: none; */
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #767196;
  padding: 0.6rem 1.75rem;
}

.modal label {
  font-size: 14px;
}

.modal input {
  padding: 0.5rem 0.75rem;
  font-size: 15px;
  letter-spacing: 1px;
}

#userAdd button[type="submit"],
.filter button[type="submit"] {
  /* box-shadow: none; */
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 0.6rem 1rem;
}
#createNew {
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 0.6rem 3rem;
}

#userAdd button[type="reset"],
.filter button[type="reset"] {
  /* box-shadow: none; */
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #767196;
  padding: 0.6rem 1.75rem;
}

#userAdd label,
.filter label {
  font-size: 14px;
  color: #151940;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

#userAdd input,
.filter input {
  padding: 0.5rem 0.75rem;
  font-size: 15px;
  letter-spacing: 1px;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
}

#userAdd select,
.filter select {
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  font-size: 15px;
  letter-spacing: 1px;
}

#userAdd .react-select__input,
.filter .react-select__input {
  padding-left: 0px;
  padding-right: 0px;
}

.userModalCancel {
  box-sizing: border-box;
  /* position: absolute; */
  width: 136px;
  height: 45px;
  left: 941px;
  top: 754px;
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin-right: 1.5rem;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Grey Text */

  color: #767196;
}

#userSave button[type="submit"] {
  width: 136px;
  height: 50px;
  left: 1101px;
  top: 754px;
  background: #4263eb;
  border-radius: 8px;
}
#modalhead {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #151940;
  text-align: center;
}
#downloadbtn {
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 0.4rem 1rem;
  height: "45px";
}
#clearbtn {
  background: #4263eb;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none;
  padding: 0.4rem 1rem;
  height: "45px";
}

.branchModalCancel {
  box-sizing: border-box;
  /* position: absolute; */
  width: 136px;
  height: 45px;
  left: 941px;
  top: 754px;
  background: #f8f8f8;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin-right: 1.5rem;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  /* Grey Text */

  color: #767196;
}

.resendBtn {
  borderradius: 6px;
  textalign: center;
  padding: 5px 9px;
  lineheight: 10px;
  backgroundcolor: #4263eb;
  color: #ffffff;
  fontweight: 600;
  fontsize: 12px;
  height: 30px;
  width: 70px;
}
