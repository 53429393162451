.dropdown-toggle {
    padding: 0.25rem 0.5rem; /* Adjust the values to change the padding */
  }
  .dropdown-menu {
    padding: 0.5rem; /* Adjust the value to reduce the padding */
  }
  
  .dropdown-item {
    padding: 0.25rem 1rem; /* Adjust the values to reduce the vertical and horizontal padding */
  }
    