#gst-billing {
    background-image: url('../../images/wave_1.png'), url('../../images/wave_2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

#gst-billing .title {
    font-weight: 700;
    font-size: 52px;
    line-height: 70px;
}

#gst-billing p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

#gst-billing .notify {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #23D598;
}

#partners .row {
    background: #F5F6F8;
}

#partners h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
}

#manage-inventory h1 {
    font-weight: 600;
    font-size: 42px;
    line-height: 60px;
    color: #151940;
}

#manage-inventory p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

#manage-inventory ul li {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #767196;
}

#verified-customer h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
}

#verified-customer p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

#verified-customer ul li {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #767196;
}

#verified-customer .notify {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}

#sale-purchase h1 {
    font-weight: 600;
    font-size: 42px;
    line-height: 60px;
    color: #151940;
}

#sale-purchase p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

#sale-purchase ul li {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #767196;
}

#gst-filling h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 65px;
}

#gst-filling p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

#gst-filling ul li {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    color: #767196;
}

#gst-filling .notify {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}

#why-us {
    background: #F1F1FF;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

#why-us h1 .highlight {
    color: #3734A9;
}

#why-us h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #151940;
}

#why-us small {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #767196 !important;
}

#why-us img {
    border-radius: 50%;
    padding: 0.8rem;
    background: #3734A9;
}

#why-us .card {
    background: #FFFFFF;
    border-radius: 20px;
}

#why-us .card:hover {
    background: #3734A9;
}

#why-us .card:hover h5 {
    color: #FFFFFF !important;
}

#why-us .card:hover small {
    color: #E1DEF3 !important;
}

#why-us .card:hover img {
    background: rgba(255, 255, 255, 0.24);
}

#testimonials .notify {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.03em;
}

#testimonials h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #151940;
}

#testimonials p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #151940;
}

#testimonials h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #151940;
}

#testimonials h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #767196;
}

.mb-6 {
    margin-bottom: 4rem;
}

.mb-7 {
    margin-bottom: 5rem;
}

#faq h1 {
    font-weight: 600;
    font-size: 34px;
    line-height: 63px;
    color: #151940;
}

#faq button {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #151940;
}

#faq p {
    font-weight: 400;
    font-size: 13px;
    line-height: 29px;
    color: #767196;
    opacity: 0.8;
}

#faq .accordion-item, #faq .accordion-button {
    background: #F1F1FF !important;
}

#faq .accordion-button {
    box-shadow: none !important;
}