@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1200px;
    }
}

.sidebar {
    min-width: 275px;
    width: 275px;
}

.content {
    width: 100%;
    background: #F4F7FE;
    border-radius: 18px;
}

.btn {
    border: none;
    border-radius: 47px;
    /*box-shadow: 0px 67px 80px rgba(55, 52, 169, 0.07), 0px 43.4259px 46.8519px rgba(55, 52, 169, 0.0531481), 0px 25.8074px 25.4815px rgba(55, 52, 169, 0.0425185), 0px 13.4px 13px rgba(55, 52, 169, 0.035), 0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815), 0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519);*/
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #FFFFFF;
}

.btn-primary {
    background: #3734A9;
}

.form-control:focus,
.form-select:focus {
    /* box-shadow: none; */
    border-color: #ced4da;
}

.text-primary {
    color: #3734A9 !important;
}

.w-90 {
    width: 90%;
}

.bg-lightgrey {
    background-color: #ced4da;
}

.pb-8 {
    padding-bottom: 7rem !important;
}

