#breadcrumb {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
}

#breadcrumb ol li {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #828282;
}

#breadcrumb ol .active {
    color: #092C4C;
    }

#paginate li:first-child {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #151940;
}

#paginate .page-item.active .page-link {
    background: #4263EB;
    border: #4263EB;
}
.dataTable tr td.fitwidth {
    width: 1px;
    white-space: nowrap;
}
.form-check{
    display: flex;
    align-items: center;
}
.form-check-input[type=checkbox]{
    min-height: 26px;
    margin-right: 10px;
}
.datePicker{
    background:  url(../images/calendar.svg) no-repeat;
    background-position: calc(100% - 14px );
    cursor: pointer;
}
.form-check-label{
    color:#000;
    padding-top: 6px;
    margin-bottom: 0;
}
.tags{
    display: flex;
    padding-left: 0;
    list-style-type: none;
    flex-wrap: wrap;
    
}
.tags li{     
    
    color:#000;
    margin-right: 5px;
    background: #ccc;
    padding: 4px 9px;
    border-radius: 5px;
    
}
.tags li span *{
    pointer-events: none;
}
.user-assign-table .form-select{
    letter-spacing: 0;
    padding: 0.3rem 0rem 0.3rem 0.3rem !important;
}
.assign-user-modal .btn.add{
    font-size: 0.75rem;
    border-radius: 0.25rem !important;
    line-height: 1.25rem;
    margin-bottom: 0.75rem;
}
.assign-user-modal .modal-body {
    padding-top: 0;
}
.assign-user-modal  .form-control{
    padding: 0.40rem 0.75rem;
}
.uploadBox{
    position: relative;
    width: 100px;
}
.uploadBox .form-control{
    visibility: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}
.uploadThumbnial {
    display: inline-flex;
    width: 100px;
    height: 100px;
    text-align: center;
    background: #e9ecef;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}
.previewBox{
    display: inline-flex;
    width: 100px;
    height: 100px;
    text-align: center;
    background: #e9ecef;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
   
}
.previewBox  img{
    width: initial;
    height:100%;
}
.removeImage{
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    background: #ffffff;
}
.react-select__input{
    width: 100%;
    grid-area: initial;
}
.download-link a{
    color: #ababab;
    text-decoration: none;
    font-size: 14px;
}
.download-link a:hover{
    text-decoration: underline;
}
.form-control:focus{
    box-shadow: none;
}
.custom-select{
    letter-spacing: 0;
    width: -webkit-fill-available;
    padding: 0.5rem 0rem 0.5rem 0.8rem !important;
    margin-bottom: 15px;
}
.custom-select:focus{
    box-shadow: none;
}

.text-theme-pink {
    color: #FE2F7B;
}

.text-theme-gray {
    color: #667085;
}

.text-theme-lightgray {
    color: #98A2B3;
}

.bg-theme-pink {
    background-color: #FE2F7B;
}

.bg-theme-lightpink {
    background-color: #FFF5F9;
}
.bg-theme-cardgray {
    background-color: #FCFCFD;
}

.on-hover:hover {
    background-color: #FFF0F6;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);;
}

.badge-bg-lightpink {
    background-color: #FFEEF4;
}

.nav-link {
    color: #667085;
    font-weight: 400;
}

.nav-pills .nav-link.active {
    background-color:#FFF0F6;
    color:#FE2F7B;
}

.card-date {
    font-weight: 500;
}

.address-clamp {
    max-height: 2.4em; /* Adjust the height as per your requirement */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to display */
    -webkit-box-orient: vertical;
}

.text-theme-darkgray {
    color: #101828;
}

.vertical-hr {
    margin: 1em 2em;
    width: 0.1em;
    height: 5em;
    background-color: #D0D5DD;
}

.vertical-hr-xl {
    margin: 1em 2em;
    width: 0.12em;
    height: 7.5em;
    background-color: #D0D5DD;
}

.about-clamp {
    max-height: 2.4em; /* Adjust the height as per your requirement */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to display */
    -webkit-box-orient: vertical;
}

.bg-theme-lavendar {
    background: linear-gradient(135deg, #868CFF 0%, #4318FF 100%);
}

.fs-small {
    font-size: smaller;
}
  