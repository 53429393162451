#header p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #A4AFD1;
}

#header h1 {
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    color: #151940;
}

#header input {
    letter-spacing: 0;
    font-size: 14px;
}

#header input::placeholder {
    letter-spacing: 0;
    color: #B2BAC8;
}

#sidebar {
    background: linear-gradient(135deg, #868CFF 0%, #4318FF 100%);
    border-radius: 24px;
}

#sidebar, #sidebar p {
    line-height: 18px !important;
    font-size: 14px;
}

#sidebar hr {
    border: 1px solid #F4F7FE;
}

#sidebar a img {
    height: 1rem;
}

#sidebar .active {
    background: #FFFFFF;
    border-radius: 8px !important;
    color: #3734A9 !important;
    font-weight: 600;
}

#navbar {
    box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.05);
}

#navbar .nav-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #767196;
}

#navbar .btn-primary-custom {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-inline: 2rem;
}

#navbar .active {
    position: relative;
}

#navbar .active::after {
    content: '';
    position: absolute;
    left: 15%;
    bottom: 10%;
    width: 70%;
    height: 0.15rem;
    text-align: center;
    background-color: #3734A9;
    border-radius: 0.15rem;
}

#footer #newsletter {
    background: linear-gradient(0deg, #3734A9 65%, #FFFFFF 35%);
}

#footer #newsletter #ready-to-start {
    background: #FAD551;
    border-radius: 30px;
}

#footer #newsletter #ready-to-start h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 52px;
    color: #151940;
}

#footer #newsletter #ready-to-start p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #151940;
}

#footer #newsletter #subscribe h2 {
    font-weight: 600;
    font-size: 26px;
    color: #FFFFFF;
}

#footer #newsletter #subscribe p {
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

#footer #newsletter #subscribe button {
    background: #FF805C;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    padding-inline: 2rem;
    margin-left: -9.3rem;
    margin-bottom: 2px;
}

#footer #newsletter #subscribe input {
    border-radius: 47px;
    letter-spacing: 0.5px;
    padding-left: 1rem !important;
    padding-right: 10rem !important;
}

#footer #newsletter #subscribe input::placeholder {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    color: #151940;
    opacity: 0.2;
}

#footer #navigation {
    background: #1B1C31;
    color: #FFFFFF;
    position: relative;
}

#footer #navigation .logo {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;
}

#footer #navigation p {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 1px;
    color: #FFFFFF;
    opacity: 0.4;
}

#footer #navigation .nav-list h6 {
    font-weight: 600;
    font-size: 17px;
    color: #FFFFFF;
    margin-bottom: 1.8rem;
}

#footer #navigation ol li {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

#footer #navigation ol li a {
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
    text-decoration: none;
}

#footer #copyright {
    background: #161727;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.me-6 {
    margin-right: 4rem;
}

.pt-7 {
    padding-top: 5rem;
}

.py-6 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.img-flip-x {
    transform: scaleX(-1);
}