.table-striped {
  border-color: rgb(167, 203, 212);
}

.table-striped thead tr {
  background-color: rgb(168, 233, 245);
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(167, 203, 212, 0.336);
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(255, 255, 255);
}

#manager-name {
  text-align: center;
  overflow-wrap: break-word;
}
.Responsive {
  display: none;
}
.website {
  display: block;
}
.card_box {
  padding: 15px;
  min-height: 300px;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;

  background: aliceblue;
  color: rgb(0, 0, 0);
}

.card_box h2 {
  text-transform: capitalize;
}

@media screen and (max-width: 800px) {
  .Responsive {
    display: block;
  }
  .website {
    display: none;
  }
  .mainpage {
    color: black;
  }
}
